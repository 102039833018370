<!-- 作业详情 单题切换  -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
.page{
    position: relative;
	width: 100%;
    height: 100%;
    padding-bottom: 80px;
}
*{
	box-sizing: border-box;
}
.content{
	padding: 32px 0px 0;
    overflow: auto;
    height: 100%;
	.encourageImg{
		width: 576px;
		height: 151px;
		display: block;
	}
	.emphasis{
		background: #FDFDFD;
        border-radius: 14px;
        border: 1px solid #BDCADC;
        padding: 18px;
        margin-bottom: 24px;
		.h2{
            height: 30px;
            font-size: 16px;
            font-weight: bold;
            color: #222222;
            line-height: 30px;
            margin-bottom: 10px;
			.iconfont{
                font-weight: initial;
                color: #FFFFFF;
                width: 26px;
                height: 26px;
                font-size: 14px;
                line-height: 26px;
                text-align: center;
                background: linear-gradient(146deg, #00D0FA 0%, #00A1FA 100%);
                border-radius: 50%;
                margin-right: 10px;
			}
		}
		.h4{
            font-size: 16px;
            font-weight: 400;
            color: #1DBB62;
            line-height: 26px;
            margin-bottom: 6px;
		}
		.p{
			font-size: 16px;
			font-weight: 400;
			color: #444444;
			line-height: 26px;
		}
	}
}

.solutionDrawImg{
	display: flex;
	padding:16px;
	border-radius: 14px;
	margin-bottom: 16px;
	.drawImg{
		margin: 0;
	}
	.iconfont{
		display: none;
	}
	.icona-dui{
		margin-right: 12px;
		font-size: 34px;
		font-weight: initial;
		color: $comGreen;
	}
	.icona-cuo{
		margin-right: 24px;
		font-size: 22px;
		font-weight: initial;
		color: $comRed;
	}
	&.correct{
		background: rgba(29,187,98,0.1);
		.icona-dui{
			display: inline-block;
		}
	}	
	&.mistake{
		background: rgba(255,77,79,0.1);
		.icona-cuo{
			display: inline-block;
		}
	}
}
</style>

<template>
    <div class="page">
		<div class="content">
			<div class="questions" v-if="homeworkItem.syque_typ_name">
				<div class="type flex_bet_align">
					{{homeworkItem.syque_typ_name}}
					<el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
				</div>
				<div class="topic">
					<div class="richblock" v-html="homeworkItem.sthom_que_title"></div>
					<img class="selectimg" v-if="homeworkItem.sthom_que_image" :src="homeworkItem.sthom_que_image" alt="">
					<!-- <el-image v-if="homeworkItem.sthom_que_image" style="width: 100px; height: 100px" :src="homeworkItem.sthom_que_image" :preview-src-list="[homeworkItem.sthom_que_image]"></el-image> -->
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
				<div v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index">
						<!-- mistake错误   correct正确-->
						<div class="li flex" :class="item.is_angser==1&&sthom_wor_answer_type!=2?(item.sthom_que_ite_isanswer==10?'correct':'mistake'):''">
							<div class="iconfont icona-dui">&#xe6db;</div>
                            <div class="iconfont icona-cuo">&#xe6dc;</div>
							<div class="number">{{item.sthom_que_ite_code}}.</div>
							<div class="flex_1">
								<div class="flex_1 richflex" v-if="item.sthom_que_ite_title" v-html="item.sthom_que_ite_title"></div>
								<img class="selectimg" v-if="item.sthom_que_ite_image" :src="item.sthom_que_ite_image" alt="">
								<!-- <el-image v-if="item.sthom_que_ite_image" style="width: 100px; height: 100px" :src="item.sthom_que_ite_image" :preview-src-list="[item.sthom_que_ite_image]"></el-image> -->
							</div>
						</div>
					</div>
				</div>
				<!-- 单项填空 -->
				<div v-if="homeworkItem.syque_typ_id==3&&sthom_wor_answer_type!=2">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index">
						<div class="li flex" :class="item.sthom_que_ite_isanswer==10?'correct':'mistake'" v-if="item.sthom_que_ite_title">
                            <div class="iconfont icona-dui">&#xe6db;</div>
                            <div class="iconfont icona-cuo">&#xe6dc;</div>
							<div class="richflex" v-html="item.sthom_que_ite_title"></div>
						</div>
					</div>
					<div class="solutionDrawImg" v-if="homeworkItem.sthom_que_stuanswer_image">
						<div class="iconfont icona-dui" v-if="homeworkItem.sthom_que_isstustatus==10">&#xe6db;</div>
						<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
						<img class="selectimg" v-if="homeworkItem.sthom_que_stuanswer_image" :src="homeworkItem.sthom_que_stuanswer_image" alt="">
						<!-- <el-image v-if="homeworkItem.sthom_que_stuanswer_image" style="width: 100px; height: 100px" :src="homeworkItem.sthom_que_stuanswer_image" :preview-src-list="[homeworkItem.sthom_que_stuanswer_image]"></el-image> -->
					</div>
				</div>
				<!-- 多项填空 -->
				<div v-if="homeworkItem.syque_typ_id==4&&sthom_wor_answer_type!=2">
					<div v-if="isshowitem(homeworkItem.items)">
						<div v-for="(item,index) in homeworkItem.items" :key="index">
							<!--  :class="item.is_angser==1?'correct':'mistake'" 单题判断对错 -->
							<div class="li flex" :class="[homeworkItem.sthom_que_isstustatus==10?'correct':'mistake',index<homeworkItem.items.length-1?'mb-0':'']">
								<div class="iconfont icona-dui" :style="index>0?'opacity: 0;':''">&#xe6db;</div>
								<div class="iconfont icona-cuo" :style="index>0?'opacity: 0;':''">&#xe6dc;</div>
								<div v-if="item.answer" class="richblock" v-html="item.answer"></div>
							</div>
						</div>
					</div>
					<div class="solutionDrawImg" v-if="homeworkItem.sthom_que_stuanswer_image">
						<div class="iconfont icona-dui" v-if="homeworkItem.sthom_que_isstustatus==10">&#xe6db;</div>
						<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
						<img class="selectimg" v-if="homeworkItem.sthom_que_stuanswer_image" :src="homeworkItem.sthom_que_stuanswer_image" alt="">
						<!-- <el-image v-if="homeworkItem.sthom_que_stuanswer_image" style="width: 100px; height: 100px" :src="homeworkItem.sthom_que_stuanswer_image" :preview-src-list="[homeworkItem.sthom_que_stuanswer_image]"></el-image> -->
					</div>
				</div>
				<!-- 判断题 -->
				<div v-if="homeworkItem.syque_typ_id==5">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index">
						<!-- mistake错误   correct正确-->
						<div class="li flex_content_center" :class="item.is_angser==1&&sthom_wor_answer_type!=2?(item.sthom_que_ite_isanswer==10?'correct':'mistake'):''">
							<div class="iconfont icona-dui">&#xe6db;</div>
                            <div class="iconfont icona-cuo">&#xe6dc;</div>
							<div class="number">{{item.sthom_que_ite_code}}.</div>
							<div class="flex_1 richflex" v-html="item.sthom_que_ite_title"></div>
						</div>
					</div>
				</div>
				<!--解答题 -->
				<div v-if="(homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8)&&sthom_wor_answer_type!=2">
					<div class="li flex_content_center" :class="homeworkItem.sthom_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.sthom_que_stuanswer[0]">
						<div class="iconfont icona-dui">&#xe6db;</div>
						<div class="iconfont icona-cuo">&#xe6dc;</div>
						<div class="flex_1" v-if="homeworkItem.sthom_que_stuanswer[0]">
							<span class="richinline" v-html="homeworkItem.sthom_que_stuanswer[0]"></span> 
						</div>
					</div>
					<div class="li solutionDrawImg " :class="homeworkItem.sthom_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.sthom_que_stuanswer_image">
						<div class="iconfont icona-dui" v-if="homeworkItem.sthom_que_isstustatus==10">&#xe6db;</div>
						<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
						<img class="selectimg" v-if="homeworkItem.sthom_que_stuanswer_image" :src="homeworkItem.sthom_que_stuanswer_image" alt="">
						<!-- <el-image v-if="homeworkItem.sthom_que_stuanswer_image" style="width: 100px; height: 100px" :src="homeworkItem.sthom_que_stuanswer_image" :preview-src-list="[homeworkItem.sthom_que_stuanswer_image]"></el-image> -->
					</div>
				</div>
				<!-- 组合题 -->
				<div v-if="homeworkItem.syque_typ_id==14">
					<div v-for="(item,index) in homeworkItem.childrenQuestion" :key="index">
						<div class="topic">
							<div class="flex_wrap">{{index+1}}.<span class="richflex" v-html="item.sthom_que_title"></span></div>
							<img class="selectimg" v-if="item.sthom_que_image" :src="item.sthom_que_image" alt="">
							<!-- <el-image v-if="item.sthom_que_image" style="width: 100px; height: 100px" :src="item.sthom_que_image" :preview-src-list="[item.sthom_que_image]"></el-image> -->
						</div>
						<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
						<div v-if="item.syque_typ_id==1||item.syque_typ_id==2">
							<!-- mistake错误   correct正确-->
							<div v-for="(item2,index2) in item.question_item" :key="index2">
								<div class="li flex" :class="item2.is_angser==1&&sthom_wor_answer_type!=2?(item2.sthom_que_ite_isanswer==10?'correct':'mistake'):''">
									<div class="iconfont icona-dui">&#xe6db;</div>
                            		<div class="iconfont icona-cuo">&#xe6dc;</div>
									<div class="number">{{item2.sthom_que_ite_code}}.</div>
									<div class="flex_1">
										<div class="flex_1 richflex" v-html="item2.sthom_que_ite_title"></div>
										<img class="selectimg" v-if="item2.sthom_que_ite_image" :src="item2.sthom_que_ite_image" alt="">
										<!-- <el-image v-if="item2.sthom_que_ite_image" style="width: 100px; height: 100px" :src="item2.sthom_que_ite_image" :preview-src-list="[item2.sthom_que_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
						</div>
						<!-- 单项填空 -->
						<div v-if="item.syque_typ_id==3&&sthom_wor_answer_type!=2">
							<div v-for="(item2,index2) in item.question_item" :key="index2">
								<div class="li flex" :class="item2.sthom_que_ite_isanswer==10?'correct':'mistake'" v-if="item2.sthom_que_ite_title">
									<div class="iconfont icona-dui">&#xe6db;</div>
                            		<div class="iconfont icona-cuo">&#xe6dc;</div>
									<div v-html="item2.sthom_que_ite_title"></div>
								</div>
							</div>
							<div class="solutionDrawImg" v-if="item.sthom_que_stuanswer_image">
								<div class="iconfont icona-dui" v-if="item.sthom_que_isstustatus==10">&#xe6db;</div>
								<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
								<img class="selectimg" v-if="item.sthom_que_stuanswer_image" :src="item.sthom_que_stuanswer_image" alt="">
								<!-- <el-image v-if="item.sthom_que_stuanswer_image" style="width: 100px; height: 100px" :src="item.sthom_que_stuanswer_image" :preview-src-list="[item.sthom_que_stuanswer_image]"></el-image> -->
							</div>
						</div>
						<!-- 多项填空 -->
						<div v-if="item.syque_typ_id==4&&sthom_wor_answer_type!=2">
							<div v-if="isshowitem(item.items)">
								<div v-for="(item2,index2) in item.items" :key="index2">
									<!-- item2.is_angser==1?'correct':'mistake'   单题判断对错 -->
									<div class="li flex" :class="[item.sthom_que_isstustatus==10?'correct':'mistake',index<item.items.length-1?'mb-0':'']">
										<div class="iconfont icona-dui" :style="index2>0?'opacity: 0;':''">&#xe6db;</div>
                            			<div class="iconfont icona-cuo" :style="index2>0?'opacity: 0;':''">&#xe6dc;</div>
										<div v-if="item2.answer"><span class="richinline" v-html="item2.answer"></span></div>
									</div>
								</div>
							</div>
							<div class="solutionDrawImg" v-if="item.sthom_que_stuanswer_image">
								<div class="iconfont icona-dui" v-if="item.sthom_que_isstustatus==10">&#xe6db;</div>
                            	<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
								<img class="selectimg" v-if="item.sthom_que_stuanswer_image" :src="item.sthom_que_stuanswer_image" alt="">
								<!-- <el-image v-if="item.sthom_que_stuanswer_image" style="width: 100px; height: 100px" :src="item.sthom_que_stuanswer_image" :preview-src-list="[item.sthom_que_stuanswer_image]"></el-image> -->
							</div>
						</div>
						<!-- 判断题 -->
						<div v-if="item.syque_typ_id==5">
							<div v-for="(item2,index2) in item.question_item" :key="index2">
								<!-- mistake错误   correct正确-->
								<div class="li flex_content_center" :class="item2.is_angser==1&&sthom_wor_answer_type!=2?(item2.sthom_que_ite_isanswer==10?'correct':'mistake'):''">
									<div class="iconfont icona-dui">&#xe6db;</div>
                            		<div class="iconfont icona-cuo">&#xe6dc;</div>
									<div class="number">{{item2.sthom_que_ite_code}}.</div>
									<div class="flex_1 richflex" v-html="item2.sthom_que_ite_title"></div>
								</div>
							</div>
						</div>
						<!--解答题 -->
						<div v-if="(item.syque_typ_id==7||item.syque_typ_id==8)&&sthom_wor_answer_type!=2">
							<div class="li flex_content_center" :class="item.sthom_que_isstustatus==10?'correct':'mistake'" v-if="item.sthom_que_stuanswer[0]">
								<div class="iconfont icona-dui">&#xe6db;</div>
                            	<div class="iconfont icona-cuo">&#xe6dc;</div>
								<div class="flex_1" v-if="item.sthom_que_stuanswer[0]"><em class="richinline" v-html="item.sthom_que_stuanswer[0]"></em></div>
							</div>
							<div class="solutionDrawImg" v-if="item.sthom_que_stuanswer_image">
								<div class="iconfont icona-dui" v-if="item.sthom_que_isstustatus==10">&#xe6db;</div>
                            	<div class="iconfont icona-cuo" v-else>&#xe6dc;</div>
								<img class="selectimg" v-if="item.sthom_que_stuanswer_image" :src="item.sthom_que_stuanswer_image" alt="">
								<!-- <el-image v-if="item.sthom_que_stuanswer_image" style="width: 100px; height: 100px" :src="item.sthom_que_stuanswer_image" :preview-src-list="[item.sthom_que_stuanswer_image]"></el-image> -->
							</div>
						</div>
						<div class="emphasis">   <!-- v-if="item.sthom_que_analysis" -->
							<div class="h2 flex_align">
								<div class="iconfont">&#xe6d9;</div>
								答案解析
							</div>
							<div v-if="sthom_wor_answer_type!=2">
								<!-- item.syque_typ_id!=7&&item.syque_typ_id!=8 解答题不实现正确答案 -->
								<div class="h4" v-if="item.syque_typ_id!=7&&item.syque_typ_id!=8">正确答案：<span class="richinline" v-html="item.sthom_que_succ_answer"></span></div>
							</div>
							<div class="p richblock" v-if="item.sthom_que_analysis" v-html="item.sthom_que_analysis"></div>
							<div class="noexplain" v-else>暂无解析</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 有值并且不是组合提 -->
			<div class="emphasis" v-if="homeworkItem.syque_typ_id!=14">  <!-- homeworkItem.sthom_que_analysis&& -->
				<div class="h2 flex_align">
					<div class="iconfont">&#xe6d9;</div>
					答案解析
				</div>
				<div v-if="sthom_wor_answer_type!=2">
					<!-- 解答题不显示正确答案 -->
					<div class="h4" v-if="homeworkItem.syque_typ_id!=7&&homeworkItem.syque_typ_id!=8">正确答案：<span class="richinline" v-html="homeworkItem.sthom_que_succ_answer"></span></div>
				</div>
				<div class="p richblock" v-if="homeworkItem.sthom_que_analysis" v-html="homeworkItem.sthom_que_analysis"></div>
				<div class="noexplain" v-else>暂无解析</div>
			</div>
		</div>
		<div class="flotageBtn bg-c-f">
			<div class="combtn1 mr-24" v-if="optIndex==0">上一题</div>
			<div class="combtn3 mr-24" v-else @click="changePractice(optIndex-1)">上一题</div>
			<div class="combtn1" v-if="optIndex==homework_list.length-1">下一题</div>
			<div class="combtn3" v-else @click="changePractice(optIndex+1)">下一题</div>
		</div>
	</div>
</template>

<script>
import { get_question_list } from "@api/home"
export default {
    name: 'jobDetailsItem',
    data() {
        return {
			//选中下标
			optIndex:0,
			//题目id
			sthom_wor_id:0,
			//课题列表
			homework_list:[],
			//单题内容
			homeworkItem:{},
			// 2上传的图片答题 
			sthom_wor_answer_type:2,
        }
    },
    created() {
        this.optIndex=parseInt(this.$route.params.index)
        this.sthom_wor_answer_type=parseInt(this.$route.params.sthom_wor_answer_type)
        this.sthom_wor_id=this.$route.params.id
        this.getData();
    },
    methods: {
        async getData() {
            let { data } = await get_question_list({sthom_wor_id:this.sthom_wor_id});
			this.homework_list=data
			this.homeworkItem=data[this.optIndex]
            this.$forceUpdate();
        },
       //切换题
		changePractice(optIndex){
			this.optIndex=optIndex
			this.homeworkItem=this.homework_list[optIndex]
		},
		//是否有作答
		isshowitem(list){
			let isshows = list.some(item=>{
					return item.answer.length>0
			})
			return isshows
		}
      
    },
}
</script>